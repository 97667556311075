import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import { socialAccount } from './ButtonShareMobile';
import './button.css';

const ButtonShareDesktop = ({ url }) => (
  <>
    <div className="flex flex-row justify-start">
      <StaticImage
        className="text-primary mr-3"
        src="../../images/share.svg"
        alt="share"
      />
      <h4 className="text-primary font-bold">Bagikan</h4>
    </div>
    {socialAccount.map((sosmed, idx) => (
      <a
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        href={`${sosmed.url}${url}`}
        className="icon-social-media"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={sosmed.name}
      >
        <FontAwesomeIcon icon={sosmed.icon} className="text-white" size="3x" />
      </a>
    ))}
  </>
);

ButtonShareDesktop.propTypes = {
  url: propTypes.string,
};

ButtonShareDesktop.defaultProps = {
  url: '',
};

export default ButtonShareDesktop;
